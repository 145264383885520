import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { waitForTransactionReceipt } from '@wagmi/core';
import {
  smartWalletNFTMintButtonPressEvent,
  smartWalletNFTMintResultEvent,
  smartWalletNFTMintTimeMetricEvent,
  useSmartWalletNFTMintButtonViewed,
} from 'cb-wallet-analytics/smart-wallet-nft';
import { useNftMint } from 'cb-wallet-data/hooks/Mint/useNftMint';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';
import { selectEthereumNetworkFromChainId } from 'cb-wallet-data/stores/Networks/utils/selectEthereumNetworkFromChainId';
import { useGetWalletsForAccount } from 'cb-wallet-data/stores/Swap/hooks/useGetWalletsForAccount';
import { getFeeWallet } from 'cb-wallet-data/stores/Wallets/hooks/useTxFeeWallet';
import { Button } from 'wallet-cds-web/components/Button';
import { Spinner } from '@cbhq/cds-web/loaders/Spinner';

import { useGetProviderByWallet } from ':dapp/connection/hooks/useGetProviderByWallet';
import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';
import { MintSuccessModal } from ':dapp/pages/nft/mint/[contractAddress]/components/MintSuccessModal';

const PANO_SOURCE = 'panorama';
const BLOCKCHAIN_SYMBOL = 'ETH';
const BASE_CHAIN_ID = 8453;
const SMART_WALLET_NFT_CONTRACT_ADDRESS = '0x76c7104567b5D32D4B084C8034724f9103F285be';

const messages = defineMessages({
  mintButtonLabel: {
    defaultMessage: 'Mint for free',
    description: 'Label for the mint button',
  },
  collectionName: {
    defaultMessage: 'Introducing Smart Wallet',
    description: 'Name of smart wallet NFT collection',
  },
});

type SmartWalletNFTMintButtonProps = {
  onClose: () => void;
  testID?: string;
};

export function SmartWalletNFTMintButton({ onClose, testID }: SmartWalletNFTMintButtonProps) {
  const getWalletsForAccount = useGetWalletsForAccount();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { account } = usePreferredAccount({
    blockchainSymbol: BLOCKCHAIN_SYMBOL,
    feature: WalletPreferenceFeature.Mint,
  });

  const network = useMemo(() => selectEthereumNetworkFromChainId(BASE_CHAIN_ID), []);
  const wacNetworkId = network?.asChain()?.wacNetworkId;

  const wallets = getWalletsForAccount(account?.id || '');

  useSmartWalletNFTMintButtonViewed();

  const feeWallet = getFeeWallet(wallets, {
    blockchain: new Blockchain(BLOCKCHAIN_SYMBOL),
    network,
    feeCurrencyCode: new CurrencyCode(BLOCKCHAIN_SYMBOL),
  });

  const walletProviderName = useGetProviderByWallet(feeWallet)?.name;

  const {
    mint,
    isLoading: mintCalldataLoading,
    error: mintError,
    mintMetrics,
  } = useNftMint({
    feeWallet,
    contractAddress: SMART_WALLET_NFT_CONTRACT_ADDRESS,
    wacNetworkId,
    quantity: '1',
    tokenId: undefined,
    source: PANO_SOURCE,
    walletProviderName,
  });

  useEffect(
    function logMintMetrics() {
      if (mintMetrics) {
        smartWalletNFTMintTimeMetricEvent({
          mintingDataloadTimeMs: mintMetrics.mintingDataloadTimeMs,
          signatureTimeMs: mintMetrics.signatureTimeMs,
        });
      }
    },
    [mintMetrics],
  );

  const handleMint = useCallback(async () => {
    setLoading(true);
    smartWalletNFTMintButtonPressEvent({ source: PANO_SOURCE, isConnected: !!account });

    try {
      const txn = await mint();
      if (txn) {
        await waitForTransactionReceipt(getConfig(), { hash: txn?.txHash as `0x${string}` });
        setLoading(false);
        setSuccess(true);
        smartWalletNFTMintResultEvent('success', { source: PANO_SOURCE, isConnected: true });
      }
      if (mintError) {
        throw mintError;
      }
    } catch (error) {
      smartWalletNFTMintResultEvent('error', { source: PANO_SOURCE, isConnected: !!account });
    } finally {
      setLoading(false);
    }
  }, [account, mint, mintError]);

  return (
    <Suspense fallback={null}>
      <Button
        block
        disabled={loading || mintCalldataLoading}
        onClick={handleMint}
        data-testid={testID}
      >
        {loading || mintCalldataLoading ? (
          <Spinner size={3} />
        ) : (
          <>{formatMessage(messages.mintButtonLabel)}</>
        )}
      </Button>
      {success && (
        <MintSuccessModal
          contractAddress={SMART_WALLET_NFT_CONTRACT_ADDRESS}
          tokenId="1"
          chainId={String(BASE_CHAIN_ID)}
          collectionName={formatMessage(messages.collectionName)}
          handleSuccessClose={onClose}
        />
      )}
    </Suspense>
  );
}
