import { ActionType, ComponentType, logMetric, MetricType } from '@cbhq/client-analytics';

import { logEvent, useLogEventOnMount } from '../utils/log';
import { EventProperties } from '../utils/types/eventProperties';

export type SmartWalletNFTMintEventProps = Pick<EventProperties, 'source' | 'isConnected'>;

export function useSmartWalletNFTMintButtonViewed() {
  useLogEventOnMount('smart_wallet_nft.button.viewed', {
    action: ActionType.view,
    componentType: ComponentType.button,
  });
}

export function useSmartWalletNFTMintModalViewed() {
  useLogEventOnMount('smart_wallet_nft.modal.viewed', {
    action: ActionType.view,
    componentType: ComponentType.modal,
  });
}

export function smartWalletNFTMintResultEvent(
  type: 'success' | 'error' | 'rejected',
  props: SmartWalletNFTMintEventProps,
) {
  logEvent(`smart_wallet_nft.mint.${type}`, {
    action: ActionType.render,
    componentType: ComponentType.unknown,
    ...props,
  });

  logMetric({
    metricName: `smart_wallet_nft.mint.${type}`,
    metricType: MetricType.count,
    value: 1,
  });
}

export function smartWalletNFTMintButtonPressEvent({
  source = 'modal',
  ...props
}: SmartWalletNFTMintEventProps) {
  logEvent('smart_wallet_nft.button.pressed', {
    action: ActionType.click,
    componentType: ComponentType.link,
    source,
    ...props,
  });
  logMetric({
    metricName: `smart_wallet_nft.button.pressed`,
    metricType: MetricType.count,
    value: 1,
  });
}

export function smartWalletNFTMintTimeMetricEvent(metrics: {
  mintingDataloadTimeMs: number | undefined;
  signatureTimeMs: number | undefined;
}) {
  if (metrics?.mintingDataloadTimeMs) {
    logMetric({
      metricName: 'smart_wallet_nft.mint.time.data',
      metricType: MetricType.distribution,
      value: metrics.mintingDataloadTimeMs,
    });
  }

  if (metrics?.signatureTimeMs) {
    logMetric({
      metricName: 'smart_wallet_nft.mint.time.signature',
      metricType: MetricType.distribution,
      value: metrics.signatureTimeMs,
    });
  }
}
