import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useMutateOCSOptIn } from 'cb-wallet-data/hooks/Gamification/useMutateOCSOptIn';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import { useToggler } from '@cbhq/cds-common';
import { Group } from '@cbhq/cds-web/layout';

import { useConnectButton } from ':dapp/components/ConnectButton/useConnectButton';
import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';

import { OCSWalletSelectorModal } from '../OCSWalletSelectorModal';
import { SmartWalletNFTModal } from '../SmartWalletNFT/SmartWalletNFTModal';

import { NuxWelcomeModal } from './NuxWelcomeModal';

export const StoreKeys_firstTimeWelcomeSeen = new LocalStorageStoreKey('firstTimeWelcomeSeen');
export const StoreKeys_smartWalletNFTSeen = new LocalStorageStoreKey('smartWalletNFTSeen');

type NuxWrapperProps = {
  children: ReactNode;
};

export function NuxWrapper({ children }: NuxWrapperProps) {
  const { account, setPreferredAccount, accountsWithBalances, hasPreferredWallet } =
    usePreferredAccount({
      blockchainSymbol: 'ETH',
      feature: WalletPreferenceFeature.OCS,
    });
  const { handleOpenModal } = useConnectButton({
    type: 'connect',
  });
  const [
    visibleNuxWelcomeModal,
    { toggleOn: openNuxWelcomeModal, toggleOff: closeNuxWelcomeModal },
  ] = useToggler();

  const [visibleWalletModal, { toggleOff: toggleOffWalletModal, toggleOn: toggleOnWalletModal }] =
    useToggler(false);

  const [
    visibleSmartWalletNFTModal,
    { toggleOn: openSmartWalletNFTModal, toggleOff: closeSmartWalletNFTModal },
  ] = useToggler();

  const [firstTimeWelcomeSeen, setFirstTimeWelcomeSeen] = useState(
    Store.get<boolean>(StoreKeys_firstTimeWelcomeSeen) || false,
  );
  const [smartWalletNFTSeen, setSmartWalletNFTSeen] = useState(
    Store.get<boolean>(StoreKeys_smartWalletNFTSeen) || false,
  );
  const [isWalletDismissed, setIsWalletDismissed] = useState(false);
  const queryClient = useQueryClient();
  const { mutate } = useMutateOCSOptIn();

  const isSmartWalletNFTEnabled = useIsFeatureEnabled('introducing_smart_wallet_mint');

  /* istanbul ignore next */
  const setAddress = useCallback(
    (accountToUse: string) => {
      const matchingAccounts = accountsWithBalances.filter(
        (a) => a.primaryAddressForDapp === accountToUse,
      );
      if (matchingAccounts.length) {
        const acct = matchingAccounts[0];
        setPreferredAccount(acct);
        const searchParams = new URLSearchParams(window.location.search);
        const referralIdParam = searchParams.get('referral_id');
        mutate(
          { walletAddress: acct.primaryAddressForDapp, referralCode: referralIdParam ?? '' },
          {
            onSuccess: () => {
              setTimeout(() => {
                queryClient.invalidateQueries({
                  queryKey: ['game_userProfileState'],
                });
              }, 2000);
            },
          },
        );
      }
    },
    // setPreferredAccount causes infinite loop
    // eslint-disable-next-line wallet/exhaustive-deps
    [accountsWithBalances, mutate, queryClient],
  );

  /* istanbul ignore next */
  const handleCloseWelcome = useCallback(() => {
    closeNuxWelcomeModal();
    setFirstTimeWelcomeSeen(true);
    Store.set<boolean>(StoreKeys_firstTimeWelcomeSeen, true);
    if (!account) {
      handleOpenModal();
    }
  }, [account, closeNuxWelcomeModal, handleOpenModal]);

  const handleCloseSmartWalletNFT = useCallback(() => {
    closeSmartWalletNFTModal();
    setSmartWalletNFTSeen(true);
    Store.set<boolean>(StoreKeys_smartWalletNFTSeen, true);
  }, [closeSmartWalletNFTModal]);

  const hasPreferredOcsWallet = useMemo(() => {
    return hasPreferredWallet(WalletPreferenceFeature.OCS);
  }, [hasPreferredWallet]);

  /* istanbul ignore next */
  const walletSelectorModalDismissed = useCallback(() => {
    if (!hasPreferredOcsWallet && account && accountsWithBalances.length) {
      setAddress(account.primaryAddressForDapp);
    }

    setIsWalletDismissed(true);
    toggleOffWalletModal();
  }, [
    account,
    accountsWithBalances.length,
    hasPreferredOcsWallet,
    setAddress,
    toggleOffWalletModal,
  ]);

  useEffect(
    function openModalFlows() {
      if (!firstTimeWelcomeSeen) {
        if (!visibleNuxWelcomeModal) {
          openNuxWelcomeModal();
        }
      } else if (
        firstTimeWelcomeSeen &&
        !visibleNuxWelcomeModal &&
        !hasPreferredOcsWallet &&
        account &&
        accountsWithBalances.length &&
        !isWalletDismissed
      ) {
        if (accountsWithBalances.length > 1 && !visibleWalletModal) {
          // multiple wallets are connected AND this is NOT first time, so select wallet
          toggleOnWalletModal();
        } else if (accountsWithBalances.length <= 1) {
          setAddress(account.primaryAddressForDapp);
        }
      } else if (!smartWalletNFTSeen && account && isSmartWalletNFTEnabled) {
        openSmartWalletNFTModal();
      }
    },
    [
      account,
      accountsWithBalances.length,
      hasPreferredOcsWallet,
      isWalletDismissed,
      setAddress,
      toggleOnWalletModal,
      visibleWalletModal,
      firstTimeWelcomeSeen,
      visibleNuxWelcomeModal,
      openNuxWelcomeModal,
      openSmartWalletNFTModal,
      visibleSmartWalletNFTModal,
      smartWalletNFTSeen,
      isSmartWalletNFTEnabled,
    ],
  );

  const nuxWelcomeModal = useMemo(() => {
    if (!visibleNuxWelcomeModal) return null;
    return <NuxWelcomeModal handleClose={handleCloseWelcome} />;
  }, [handleCloseWelcome, visibleNuxWelcomeModal]);

  const walletSelectorModal = useMemo(() => {
    if (!visibleWalletModal) return null;
    return (
      <OCSWalletSelectorModal handleClose={walletSelectorModalDismissed} setAddress={setAddress} />
    );
  }, [walletSelectorModalDismissed, setAddress, visibleWalletModal]);

  const smartWalletNFTModal = useMemo(() => {
    if (!visibleSmartWalletNFTModal || !isSmartWalletNFTEnabled) return null;
    return <SmartWalletNFTModal handleClose={handleCloseSmartWalletNFT} />;
  }, [handleCloseSmartWalletNFT, isSmartWalletNFTEnabled, visibleSmartWalletNFTModal]);

  return (
    <Group testID="ocs-nux-wrapper" width="100%" alignItems="center">
      {nuxWelcomeModal}
      {walletSelectorModal}
      {smartWalletNFTModal}
      {children}
    </Group>
  );
}
