import { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSmartWalletNFTMintModalViewed } from 'cb-wallet-analytics/smart-wallet-nft';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { RemoteImage } from '@cbhq/cds-web/media';
import { TextBody, TextTitle2 } from '@cbhq/cds-web/typography';
import { FetchableResourceProvider } from '@cbhq/instant-api-hooks-core/fetch';
import { setBaseUrl } from '@cbhq/instant-api-hooks-creator-service';

import { HomebaseModal } from ':dapp/components/HomebaseModal/HomebaseModal';
import { CB_WALLET_API_URL } from ':dapp/config/env';

import { SmartWalletNFTMintButton } from './SmartWalletNFTMintButton';

function initializeApiHooks() {
  setBaseUrl(CB_WALLET_API_URL);
}
initializeApiHooks();

const messages = defineMessages({
  title: {
    defaultMessage: 'Claim your free NFT: \n Introducing Smart Wallet',
    description: 'Title for smart wallet NFT modal',
  },
  description: {
    defaultMessage:
      "You're officially onchain! Accept this as a gift for creating your smart wallet.",
    description: 'Description for smart wallet NFT modal',
  },
  primaryCTAText: {
    defaultMessage: 'Mint for free',
    description: 'CTA for smart wallet NFT modal',
  },
});

type SmartWalletNFTModalProps = {
  handleClose: () => void;
};

export const SmartWalletNFTModal = memo(function SmartWalletNFTModal({
  handleClose,
}: SmartWalletNFTModalProps) {
  const { formatMessage } = useIntl();

  useSmartWalletNFTMintModalViewed();

  return (
    <FetchableResourceProvider>
      <HomebaseModal
        visible
        onClose={handleClose}
        testID="smart-wallet-nft-modal"
        contentSpacingTop={0}
        width={830}
      >
        <HStack gap={4} spacingHorizontal={4} spacingBottom={2}>
          <RemoteImage
            aria-hidden
            source="https://go.wallet.coinbase.com/static/SmartWalletNFTModal.gif"
            width="50%"
            testID="smart-wallet-nft-modal-image"
            resizeMode="contain"
          />
          <VStack gap={3} spacing={3}>
            <TextTitle2 as="h1" testID="smart-wallet-nft-modal-title">
              {formatMessage(messages.title)}
            </TextTitle2>
            <TextBody as="p" color="foregroundMuted" testID="smart-wallet-nft-modal-description">
              {formatMessage(messages.description)}
            </TextBody>
            <SmartWalletNFTMintButton
              onClose={handleClose}
              testID="smart-wallet-nft-modal-mint-button"
            />
          </VStack>
        </HStack>
      </HomebaseModal>
    </FetchableResourceProvider>
  );
});
